.o-prototype{
    width: 100%;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba($cool-black, 0.15);
    padding: $unit;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    user-select: none;
    margin-bottom: $unit*2;

    @media (max-width: $screen-small){
        flex-flow: column wrap;
        justify-content: flex-start;

        .o-wrapper{
            width: 100%;
        }
    }

    p:last-of-type{
        margin-bottom: 0;
        color: rgba($font-base, 0.6);
    }

    .o-prototype_actions{
        display: inline-block;
        line-height: 0;

        @media (max-width: $screen-small){
            width: 100%;
            text-align: left;
            margin-top: $unit;
            
            a{
                margin-left: -$unit-small;
                margin-bottom: -$unit-small;
            }
        }
    }
}