#sidebar-demo{
    width: 100vw;
    min-height: 100vh;
    background-color: $off-white;

    ul{
        @include size(256px, 50vh);
        display: inline-block;
        @include position(fixed, 0 0 null null);
        overflow-y: auto;

        li{
            display: block;
            color: rgba($off-black, 0.6);
            text-align: right;
        }
    }

    aside{
        @include position(fixed, 0 null null 0);

        &.is-open{
            .t-backdrop{
                background-color: rgba($cool-black, 0.6);
                pointer-events: all;
                transform: translateX(0);
                transition: transform 0ms 0ms linear, background-color 300ms 0ms $ease-in;
            }

            nav{
                transform: translate3d(0,0,0);
                box-shadow: 2px 0px 16px rgba($off-black, 0.3);
                transition: all 300ms $ease-in;
            }
        }

        .o-hamburger{
            display: inline-flex;
            @include size(48px, 48px);
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
            @include position(absolute, $unit-small null null $unit);
            cursor: pointer;
            user-select: none;

            @include touch{
                &:before{
                    transform: scale(1);
                    transition: transform 150ms $ease-in;
                }
            }

            @include hover{
                &:after{
                    transform: scale(1);
                    transition: transform 150ms $ease-in;
                }
            }

            @include active{
                &:before{
                    transform: scale(1);
                    transition: transform 150ms $ease-in;
                }
            }

            &::before,
            &::after{
                content:"";
                display: inline-block;
                @include size(48px, 48px);
                border-radius: 100%;
                transform: scale(0);
                position: absolute;
                transition: transform 150ms $ease-sharp;
                pointer-events: none;
            }

            &:after{
                background-color: rgba($primary, $primary-hover-opacity);
            }

            &:before{
                background-color: rgba($primary, $primary-active-opacity);
            }

            span{
                @include size(32px, 4px);
                margin: 4px 0;
                background-color: $off-black;
                display: block;
            }
        }

        nav{
            background-color: $white;
            box-shadow: 2px 0px 16px rgba($off-black, 0);
            @include size(256px, 100vh);
            overflow-y: auto;
            padding: $unit*2 0;
            transform: translate3d(-100%,0,0);
            transition: all 175ms $ease-sharp;
            will-change: transform;

            h3{
                color: rgba($primary, 0.87);
                padding: 0 $unit;
                font-weight: $medium;
            }

            a{
                display: block;
                width: 100%;
                height: 48px;
                background-color: transparent;
                text-transform: capitalize;
                line-height: 48px;
                padding: 0 $unit;
                font-size: 18px;
                color: rgba($off-black, 0.6);
                transition: all 150ms $ease;

                @include touch{
                    background-color: darken($white, 5);
                    color: rgba($off-black, 1);
                }

                @include hover{
                    background-color: darken($white, 3);
                    
                }

                @include active{
                    background-color: darken($white, 5);
                    color: rgba($off-black, 1);
                }
            }
        }

        .t-backdrop{
            @include size(100vw, 100vh);
            @include position(absolute, 0 null null 0);
            background-color: transparent;
            pointer-events: none;
            transform: translateX(-100%);
            cursor: pointer;
            transition: transform 0ms 300ms linear, background-color 300ms 0ms $ease-sharp;
        }
    }
}