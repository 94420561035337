.c-complex-content{
    .c-complex-content_section{
        position: relative;

        &.has-image,
        &.has-video{
            .c-complex-content_overlay{
                @include size(100%, 100%);
                @include position(absolute, 0 null null 0);
            }
        }

        &.has-image{
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }

        &.has-video{
            .c-complex-content_video{
                @include size(100%, 100%);
                @include position(absolute, 0 null null 0);

                video{
                    @include size(100%, 100%);
                    @include position(absolute, 0 null null 0);
                    object-fit: cover;
                }
            }
        }

        .c-complex-content_container{
            .c-complex-content_column{
                @media (max-width: $screen-large - 1){
                    margin: 1rem 0;
                }

                &:only-child{
                    @media (max-width: $screen-large - 1){
                        margin: 0;
                    }
                }

                &:last-child{
                    margin-bottom: 0;
                }

                &:first-child{
                    margin-top: 0;
                }
            }

            &:first-of-type{
                // padding-top: 0 !important;

                .c-complex-content_column{
                    @media (max-width: $screen-large - 1){
                        margin-top: 0;
                    }
                }
            }

            &:last-of-type{
                .c-complex-content_column{
                    &:last-child{
                        @media (max-width: $screen-large - 1){
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}