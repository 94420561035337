.o-list{
    .o-list_list{
        padding-left: 1.5rem;
        display: inline-block;
        max-width: 420px;

        &.-default{
            .o-list_item{
                &::before{
                    content:"";
                    @include size(0.75rem, 2px);
                    background-color: $primary;
                    @include position(absolute, 50% null null -1.5rem);
                    transform: translateY(-50%);
                    opacity: 0.6;
                }
            }
        }

        &.-plus{
            .o-list_item{
                &::before{
                    content:"";
                    @include size(0.75rem, 2px);
                    background-color: $primary;
                    @include position(absolute, 50% null null -1.5rem);
                    transform: translateY(-50%);
                    opacity: 0.6;
                }

                &::after{
                    content:"";
                    @include size(2px, 0.75rem);
                    background-color: $primary;
                    @include position(absolute, 50% null null -1.2rem);
                    transform: translateY(-50%);
                    opacity: 0.6;
                }
            }
        }

        .o-list_item{
            list-style: none;
            position: relative;
            margin: 1rem 0;
            font-size: 1.25rem;

            &:first-child{
                margin-top: 0;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}