.o-image{
    .o-image_shim{
        position: relative;

        .c-complex-content_column.u-1\/3\@large & {
            @media (max-width: $screen-large - 1){
                max-width: 480px;
                margin: 0 auto;
            }
        }

        &.u-ratio-36\/10{
            @media (max-width: 721px){
                min-height: 200px;
            }
        }

        .o-image_image,
        .o-image_overlay{
            @include position(absolute, 0 null null 0);
            @include size(100%, 100%);
        }

        .o-image_image{
            background-repeat: no-repeat !important;
            background-position: center !important;

            @media (max-width: $screen-large){
                background-size: cover !important;
            }
        }

        img{
            max-width: 100%;
        }
    }
}