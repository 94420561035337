.o-tooltip{
    position: relative;

    .o-tip{
        height: 32px;
        line-height: 32px;
        white-space: nowrap;
        padding: 0 16px;
        background: $primary;
        color: $white;
        text-transform: uppercase;
        font-size: 12px;
        z-index: 50;
        transform: scale(0.9) translateX(-50%);
        transform-origin: left;
        opacity: 0;
        position: absolute;
        left: 50%;
        top: calc(100% + 6px);
        pointer-events: none;
        box-shadow: 0 1px 2px rgba($cool-black, 0.15);

        html.has-touch & {
            top: auto;
            bottom: calc(100% + 4px);
        }

        @media (min-width: $screen-large){
            padding: 0 8px;
            line-height: 24px;
            height: 24px;
        }
    }

    &:hover{
        .o-tip{
            animation: tooltipPopup 1725ms linear;
        }
    }
}

@keyframes tooltipPopup{
    0%{
        opacity: 0;
        transform: scale(0.9) translateX(-50%);
    }
    8%{
        opacity: 1;
        transform: scale(1) translateX(-50%);
        animation-timing-function: $ease-in;
    }
    96%{
        opacity: 1;
        transform: scale(1) translateX(-50%);
    }
    100%{
        opacity: 0;
        transform: scale(1) translateX(-50%);
        animation-timing-function: $ease-sharp;
    }
}