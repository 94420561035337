// Headings
// ==========================================================================

/**
 * This section adjusts the base headings style such as colour, weights, and size
 * to adjust line heights please visit the readability SCSS file.
 * @see `base/_readability.scss`
 *
 * To calculate REM version of tracking divide value by 16px. Example: (0.2/16px) = 0.0125rem
 */

@mixin  base-heading {
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
}

.o-h1
{
    @include base-heading;
    @include u-h1;

    font-weight: $light;
    font-family: $font-heading;
    color: contrast($base-background-color, 0.87);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.87);
    }
}

.o-h2
{
    @include base-heading;
    @include u-h2;

    font-weight: $light;
    font-family: $font-heading;
    color: contrast($base-background-color, 0.87);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.87);
    }
}

.o-h3
{
    @include base-heading;
    @include u-h3;

    font-weight: $regular;
    font-family: $font-heading;
    color: contrast($base-background-color, 0.87);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.87);
    }
}

.o-h4
{
    @include base-heading;
    @include u-h4;

    font-weight: $regular;
    font-family: $font-heading;
    color: contrast($base-background-color, 0.6);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.6);
    }
}

.o-h5,
.o-headline,
.o-title
{
    @include base-heading;
    @include u-base-font;

    font-weight: $heavy;
    font-family: $font-heading;
    @include base-heading;
    color: contrast($base-background-color, 0.87);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.87);
    }
}

.o-h6,
.o-subtitle
{
    @include base-heading;
    @include u-h6;

    font-weight: $regular;
    font-family: $font-heading;
    @include base-heading;
    color: contrast($base-background-color, 0.6);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: contrast($dark-background-color, 0.6);
    }
}
