// Imports
// =============================================================================
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono|Montserrat:300,400,500,700');

// Typefaces
// =============================================================================
$font-sans-serif:   'Circular', 'Montserrat', sans-serif;
$font-serif:        serif;
$font-heading:      $font-sans-serif;
$font-quote:        'Circular', 'Montserrat', cursive;
$font-code:         'Share Tech Mono', monospace;

// Weights
// =============================================================================
$light: 300;
$regular: 400;
$medium: 500;
$heavy: 700;

// Typography
// =============================================================================
$font-weight: $regular;
$font-family: $font-sans-serif;
$color: $font-base;

$base-font-size: 16; // In pixels

// Transitions
// =============================================================================
$speed:         300ms;
$ease:          cubic-bezier(0.4, 0.0, 0.2, 1);
$ease-sharp:    cubic-bezier(0.4, 0.0, 0.6, 1);
$ease-in:       cubic-bezier(0.0, 0.0, 0.2, 1);
$ease-out:      cubic-bezier(0.4, 0.0, 1, 1);

// Spacing Units
// =============================================================================
$unit:          1rem;
$unit-small:    0.5rem;
$unit-tiny:     0.25rem;
$base-p-margin: 0 0 $unit;

// Container
// ==========================================================================
$container-width:           1280px;
$narrow-container-width:    840px;
$padding:                   0;

// Layout
// =============================================================================
$gutter:        1rem;
$gutter-small:  0.5rem;
$gutter-large:  2rem;

// Screen Breakpoints
// =============================================================================
$screen-xsmall:     600px;
$screen-small:      840px;
$screen-medium:     960px;
$screen-large:      1280px;
$screen-xlarge:     1440px;
$screen-xxlarge:    1600px;
$screen-xxxlarge:   1920px;

// Custom Breakpoints
// =============================================================================
$screen-container:  $container-width;
$screen-nav:        960px;
