// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
//    scrollbars naturally.
// 3. Ensure the page always fills at least the entire height of the viewport.
//
html
{
    font-family: $font-family;
    line-height: normal; /* [1] */
    font-weight: $font-weight;
    color: $font-base; /* [1] */
    overflow: hidden;
    min-height: 100vh; /* [3] */
    text-align: left;
    position: relative;


    *{
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    &.dom-is-loading{
        *{
            cursor: wait !important;
        }
    }
}

section{
    &.-primary-background{
        background: $base-background-color;
    }

    &.-secondary-background{
        background: $secondary-background-color;
    }

    &.-dark-background,
    &.has-image,
    &.has-video{
        background: $dark-background-color;
        color: $font-alt;
    }
}

body{
    background: $base-background-color;
    font-size: 1rem; /* [1] */

    main{
        min-height: 80vh;
    }
}

::selection
{
    color: $selection-text-color;
    background-color: $selection-background-color;
    text-shadow: none;
}

:root{
    font-size: 100%;
}
