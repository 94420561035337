#homepage{
    width: 100vw;
    min-height: 100vh;
    background-color: $off-white;

    section{
        min-height: 100vh;
        padding: $unit*4 0;
    }
}
