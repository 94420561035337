.o-buttons{
    .o-button{
        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }

        &.-base{
            &:first-child{
                margin-left: -8px;
            }
        }
    }
}