// Generic
// ==========================================================================

hr
{
    border: 0;
    border-bottom: solid 1px $black;
    opacity: 0.12;
    margin: 0;
    display: block;

    section.-dark-background & {
        border-color: $white;
    }
}

a
{
    color: $font-base;
    text-decoration: none;

    section.-dark-background & {
        color: $font-alt;
    }
}

button{
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: transparent;
    color: $font-base;

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: $font-alt;
    }
}

ol{
    padding: 8px 0 0 16px;
    margin: 0;
    font-size: 16px;
    text-align: left;

    ul,ol{
        padding-bottom: 2px;
    }

    li ul li{
        list-style-type: disc;
    }
}

ul{
    padding: 8px 0 0 16px;
    margin: 0;
    font-size: 16px;
    text-align: left;

    ul,ol{
        padding-bottom: 2px;
    }

    li{
        list-style-type: disc;
        min-height: 24px;
    }

    &.-circle,
    li ul{
        li{
            list-style-type: circle;
        }
    }

    &.-square,
    li ul li ul{
        li{
            list-style-type: square;
        }
    }
}

p{
    margin: $base-p-margin;

    @include u-base-font;

    a{
        color: $primary;
        text-decoration-skip-ink: none;
        transition: color 150ms $ease;
        position: relative;
        display: inline-block;

        &::after{
            content:"";
            background-color: $primary;
            @include size(100%, 2px);
            transform: scaleY(0);
            position: absolute;
            bottom: 2px;
            left: 0;
            opacity: 0;
            display: inline-block;
            transform-origin: bottom;
            transition: transform 175ms $ease-sharp, opacity 75ms 100ms $ease;
        }

        @include hover{
            color: $primary;
            transition: color 150ms $ease-in, text-decoration 150ms $ease-in;

            &:after{
                opacity: 0.87;
                transform: scaleY(1);
                transition: transform 225ms $ease-in, opacity 75ms 0ms $ease;
            }
        }
    }
}

code{
    background: $light-gray;
    border-radius: 4px;
    font-family: $font-code;
    font-size: $unit;
    margin: 0;
    padding: $unit-small $unit;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 100%;
    display: inline-block;
}

blockquote{
    border-left: solid 3px rgba($primary, 0.15);
    font-family: $font-quote;
    margin: 0 0 0 -1rem !important;
    padding: 0 0 0 1rem;
    quotes: none;
    font-size: 1.25rem;
    letter-spacing: 0.0125rem;
    line-height: 2rem;
    text-align: justify;
    color: rgba($primary, 0.87);

    section.-dark-background &,
    section.has-image &,
    section.has-video & {
        color: rgba($font-alt, 0.87);
        border-left-color: rgba($font-alt, 0.15);
    }
}
