// ==========================================================================
// Objects / Container
// ==========================================================================

//
// Page-level constraining and wrapping elements.
//
// > In programming languages the word *container* is generally used for structures
// that can contain more than one element.
// > A *wrapper* instead is something that wraps around a single object to provide
// more functionalities and interfaces to it.
// @link http://stackoverflow.com/a/13202141/140357
//

// Base container
.o-container
{
    max-width: $container-width;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: $padding;
    padding-left: $padding;
    width: 100%;

    @include u-flexbox;        //Adds flexbox modifiers from  tools/_mixins.scss
    @include u-fullscreen;     //Adds height modifiers from tools/_mixins.scss
    @include u-mobile-padding; //Adds default padding on mobile sizes from tools/_mixins.scss, configured from $unit-small in settings/_config.scss
}

// Full width (typically fullscreen) container
.o-container-full{
    width: 100%;

    @include u-flexbox;    //Adds flexbox modifiers from  tools/_mixins.scss
    @include u-fullscreen; //Adds height modifiers from tools/_mixins.scss
    @include u-mobile-padding; //Adds default padding on mobile sizes from tools/_mixins.scss, configured from $unit-small in settings/_config.scss
}

// Narrow width container
.o-container-narrow{
    max-width: $narrow-container-width;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: $padding;
    padding-left: $padding;
    width: 100%;

    @include u-flexbox;    //Adds flexbox modifiers from  tools/_mixins.scss
    @include u-fullscreen; //Adds height modifiers from tools/_mixins.scss
    @include u-mobile-padding; //Adds default padding on mobile sizes from tools/_mixins.scss, configured from $unit-small in settings/_config.scss
}

// Wraper object used to block and contain content.
// Typically used within a flexbox container.
.o-wrapper
{
    position: relative;
    display: inline-block;

    @include u-flexbox;    //Adds flexbox modifiers from  tools/_mixins.scss
    @include u-fullscreen; //Adds height modifiers from tools/_mixins.scss

    &.-top{
        vertical-align: top;
    }

    &.-middle{
        vertical-align: middle;
    }

    &.-center
    {
        display: block;
        text-align: center;
        margin: 0 auto;
    }

    &.-right
    {
        text-align: right;
    }
}