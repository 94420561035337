#events-experiment{
    width: 100vw;
    height: 100vh;
    display: inline-block;

    .o-event-list{
        display: block;
        overflow-y: scroll;
        @include size(256px, 100vh);
        @include position(absolute, 0 null null 0);
        padding: 0;
        font-size: 20px;

        @media (max-width: $screen-medium){
            height: 50vh;
            width: 100vw;
        }

        &.can-scroll{
            &:after{
                transform: translateY(0);
                transition: transform 300ms $ease-in;
            }
        }

        &::after{
            content:"";
            display: inline-block;
            @include size(100%, 64px);
            @include position(fixed, 0 null null 0);
            background: linear-gradient(to bottom, $off-white, transparent);
            pointer-events: none;
            transform: translateY(-100%);
        }

        li{
            display: inline-block;
            vertical-align: baseline;
            margin: auto;
            width: 100%;
            height: 32px;
            padding-left: $unit*2;
            line-height: 32px;
            color: rgba($off-black, 0.6);
            transition: all 150ms $ease;
            user-select: none;
            background-color: transparent;

            span{
                &.t-time{
                    color: rgba($off-black, 0.3);
                    font-size: 14px;
                }
            }

            @include hover{
                color: $notice;
                background-color: darken($off-white, 3);
            }
        }
    }

    section{
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-content: center;
        justify-content: center;
        flex-flow: column nowrap;
        background-color: $off-white;
        padding-left: 288px;

        @media (max-width: $screen-medium){
            padding-left: 0;
            padding-top: 45vh;
            padding-bottom: 5vh;
        }

        .o-event{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
            position: relative;
            margin: auto;
            width: 256px;
            height: 158px;
            cursor: pointer;
            border-radius: 4px;
            background-color: $white;
            box-shadow: 0 2px 16px rgba($cool-black, 0.15);
            color: $font-base;
            font-size: 24px;
            user-select: none;
            transition: all 150ms $ease;
            border: 2px solid transparent;

            @include touch{
                border: 2px solid $warning;
            }

            @include hover{
                background-color: lighten($notice, 38);
                box-shadow: 0 4px 16px rgba($cool-black, 0.3);
            }

            @include active{
                color: $notice;
            }
        }
    }
}
