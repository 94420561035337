// Helpers
// ==========================================================================

.u-clearfix
{
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.u-truncate
{
    @include u-truncate;
}

.u-block
{
    display: block;
}

.u-hidden{
    display: none;
    visibility: hidden;
    opacity: 0;
}

.u-lowercase{
    text-transform: lowercase !important;
}

.u-uppercase{
    text-transform: uppercase !important;
}