// Forms
// ==========================================================================

// Switch (styled single checkbox) input wrapper
// Switch inputs have a universal style
// Styled outside of the form element since they are not always form elements
.o-switch{
    height: 48px;
    width: 100%;

    .o-switch_item{
        width: 100%;
        height: 100%;

        input{
            &:checked{
                & + label{
                    .o-switch_icon{
                        .o-switch_handle{
                            transform: translateX(20px);

                            .o-switch_circle{
                                border-color: $primary;

                                section.-dark-background & {
                                    border-color: $primary-alt;
                                }
                            }
                        }

                        .o-switch_track{
                            // background: $primary;
                            // opacity: 0.54;
                        }
                    }
                }
            }
        }
        
        label{
            @include size(100%, 100%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            pointer-events: all;

            .o-switch_icon{
                // pointer-events: none;
                display: inline-flex;
                align-items: center;
                cursor: pointer;

                &:active{
                    .o-switch_handle{
                        &:before{
                            opacity: 0;
                            transition: opacity 75ms $ease-out;
                        }
    
                        &:after{
                            transform: scale(1);
                            opacity: $primary-active-opacity;
                            transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                        }
                    }
                }

                &:hover{
                    .o-switch_handle{
                        &:before{
                            transform: scale(1);
                            opacity: $primary-hover-opacity;
                            transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                        }
                    }
                }

                .o-switch_track{
                    opacity: 0.3;
                    border-radius: 7px;
                    @include size(30px, 12px);
                    background: $black;
                    transition: background 75ms $ease;
                }

                .o-switch_handle{
                    @include size(10px, 10px);
                    position: absolute;
                    transform: translateX(0);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    transition: transform 150ms $ease;

                    .o-switch_circle{
                        border: 10px solid $white;
                        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
                        border-radius: 100%;
                        position: absolute;
                        z-index: 5;
                        transition: border-color 75ms $ease;
                    }

                    &::before{
                        content:"";
                        background: $primary;
                        position: absolute;
                        display: inline-block;
                        border-radius: 100%;
                        @include size(42px, 42px);
                        transform: scale(0);
                        opacity: 0;
                        z-index: 1;
                        transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;

                        section.-dark-background & {
                            background: $white;
                        }
                    }
    
                    &::after{
                        content:"";
                        background: $primary;
                        position: absolute;
                        display: inline-block;
                        border-radius: 100%;
                        @include size(42px, 42px);
                        transform: scale(0);
                        opacity: 0;
                        z-index: 2;
                        transition: transform 75ms $ease-sharp, opacity 25ms 50ms $ease-out;

                        section.-dark-background & {
                            background: $white;
                        }
                    }
                }
            }
        }
    }
}

// Radio input wrapper
// Radio buttons have a universal style
.o-radio{
    position: relative;
    padding: 32px 0 6px;
    text-align: left;
    width: 100%;

    label{
        position: absolute;
        top: 0;
        left: 12px;
    }

    .o-radio_item{
        display: flex;
        align-items: center;
        margin: $unit $unit*2;

        @media (min-width: $screen-medium){
            display: inline-flex;
        }

        label{
            display: flex;
            align-items: center;
        }

        &:hover{
            cursor: pointer !important;

            .o-radio_icon{
                &:before{
                    transform: scale(1);
                    opacity: $primary-hover-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }   
            }
        }

        &:active{
            .o-radio_icon{
                &:before{
                    opacity: 0;
                    transition: opacity 75ms $ease-out;
                }

                &:after{
                    transform: scale(1);
                    opacity: $primary-active-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }
            }
        }

        .o-radio_icon{
            @include size(20px, 20px);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: $unit;
            position: relative;
            pointer-events: none;

            &::before{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                opacity: 0;
                transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;

                section.-dark-background & {
                    background: $white;
                }
            }

            &::after{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                opacity: 0;
                transition: transform 75ms $ease-sharp, opacity 25ms 50ms $ease-out;

                section.-dark-background & {
                    background: $white;
                }
            }

            .o-radio_outter{
                @include size(16px, 16px);
                border-radius: 100%;
                border: 2px solid $primary;
                position: absolute;

                section.-dark-background & {
                    border-color: $primary-alt;
                }
            }

            .o-radio_inner{
                transform: scale(0);
                @include size(100%, 100%);
                border: solid 10px $primary;
                border-radius: 100%;
                position: absolute;
                transition: transform 125ms $ease-sharp;

                section.-dark-background & {
                    border-color: $primary-alt;
                }
            }
        }

        input{
            &:checked{
                & + label{
                    .o-radio_icon{
                        .o-radio_inner{
                            transform: scale(0.4);
                            transition: transform 150ms $ease-in;
                        }
                    }
                }
            }

            & + label{
                vertical-align: middle;
                position: relative;
                left: 0;
                pointer-events: all;
                cursor: pointer;
            }
        }
    }
}

// Checkbox input wrapper
// Checkbox inputs have a universal style
.o-checkbox{
    position: relative;
    padding: 32px 0 6px;
    width: 100%;

    label{
        position: absolute;
        top: 0;
        left: 12px;
    }

    .o-checkbox_item{

        label{
            height: 24px;
            line-height: 24px;
            display: flex;
            align-items: center;
            margin: $unit $unit*2;
        }

        &:hover{
            cursor: pointer !important;

            .o-checkbox_icon{
                &:before{
                    transform: scale(1);
                    opacity: $primary-hover-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }   
            }
        }

        &:active{
            .o-checkbox_icon{
                &:before{
                    opacity: 0;
                    transition: opacity 75ms $ease-out;
                }

                &:after{
                    transform: scale(1);
                    opacity: $primary-active-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }
            }
        }

        .o-checkbox_icon{
            @include size(20px, 20px);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: $unit;
            position: relative;
            pointer-events: none;

            &::before{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                opacity: 0;
                transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;

                section.-dark-background & {
                    background: $white;
                }
            }

            &::after{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                opacity: 0;
                transition: transform 75ms $ease-sharp, opacity 25ms 50ms $ease-out;

                section.-dark-background & {
                    background: $white;
                }
            }

            .o-checkbox_box{
                position: absolute;
                @include size(16px, 16px);
                border-radius: 2px;
                border: 2px solid $primary;
                background: transparent;
                transition: background 75ms $ease-sharp;

                section.-dark-background & {
                    border-color: $primary-alt;
                }
            }

            .o-checkbox_check{
                position: absolute;
                transform: scale(0.5);                    
                path{
                    stroke: contrast($primary);
                    stroke-width: 3px;
                    stroke-linecap: round;
                    stroke-dasharray: 30;
                    stroke-dashoffset: 30;
                    transition: stroke-dashoffset 75ms $ease-sharp;

                    section.-dark-background & {
                        stroke: contrast($primary-alt);
                    }
                }
            }
        }

        input{
            &:checked{
                & + label{
                    .o-checkbox_icon{
                        .o-checkbox_box{
                            background: $primary;
                            transition: background 150ms $ease-in;

                            section.-dark-background & {
                                background: $primary-alt;
                            }
                        }

                        .o-checkbox_check{
                            path{
                                stroke-dashoffset: 0;
                                transition: stroke-dashoffset 150ms $ease-in;
                            }
                        }
                    }
                }
            }

            & ~ label{
                vertical-align: middle;
                position: relative;
                left: 0;
                pointer-events: all;
                cursor: pointer;
            }
        }
    }
}

// Base input wrapper
.o-input{
    height: 56px;
    width: 100%;
    position: relative;

    &.-square{
        input,
        select,
        textarea{
            border-radius: 0 !important;
        }
    }

    &.is-invalid{
        .o-help{
            opacity: 0;
            transition: opacity 25ms $ease-sharp;
        }
    }

    .-visible,
    .-hidden{
        @include position(absolute, null 12px 18px null);
        color: $off-black;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::before{
            content:"";
            background: $primary;
            position: absolute;
            display: inline-block;
            border-radius: 100%;
            @include size(32px, 32px);
            transform: scale(0);
            transform-origin: center;
            opacity: 0;
            transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;

            section.-dark-background & {
                background: $white;
            }
        }

        &::after{
            content:"";
            background: $primary;
            position: absolute;
            display: inline-block;
            border-radius: 100%;
            @include size(32px, 32px);
            transform: scale(0);
            opacity: 0;
            transition: transform 75ms $ease-sharp, opacity 25ms 50ms $ease-out;

            section.-dark-background & {
                background: $white;
            }
        }

        &:hover{
            &:before{
                transform: scale(1);
                opacity: $primary-hover-opacity;
                transition: transform 150ms $ease-in, opacity 75ms $ease-in;
            }
        }

        &:active{
            &:before{
                opacity: 0;
                transition: opacity 75ms $ease-out;
            }

            &:after{
                transform: scale(1);
                opacity: $primary-active-opacity;
                transition: transform 150ms $ease-in, opacity 75ms $ease-in;
            }
        }

        section.-dark-background & {
            color: $font-alt;
        }
    }

    &.has-content-hidden,
    &.has-content-visible{
        input{
            padding-right: 34px !important;
        }
    }

    // Used for password and pin inputs
    // The password field is hidden (default)
    &.has-content-hidden{
        .-visible{
            display: none;
        }

        .-hidden{
            display: inline-flex;
        }
    }

    // Used for password and pin inputs
    // The password field is visible
    &.has-content-visible{
        .-visible{
            display: inline-flex;
        }

        .-hidden{
            display: none;
        }
    }
}

form{
    padding: 0;
    margin: 0;

    &.-square{
        input,
        select,
        textarea{
            border-radius: 0 !important;
        }
    }

    // Text area input wrapper
    .o-textarea{
        position: relative;
        width: 100%;

        &.is-invalid{
            html.has-js & {
                .o-error{
                    opacity: 1;
                    transform: translateY(0);
                    transition: transform 150ms $ease-in, opacity 25ms $ease-in;
                }
            }

            textarea{
                border: 2px solid $error;
                padding: 31px 15px 15px;
                transition: border 150ms $ease, padding 150ms $ease;

                & + label{
                    top: 12px;
                    font-size: 12px;
                    color: $error !important;
                }
            }
        }

        &.has-value{
            textarea{
                & + label{
                    top: 12px;
                    font-size: 12px;
                }
            }
        }
    }

    // Text objects
    .o-error,
    .o-help{
        padding: $unit $unit-small 0;
        @include position(absolute, 100% null null 0);
        width: 100%;
        color: $helper-dark;
        font-size: 12px;

        section.-dark-background & {
            color: $helper-light;
        }

        svg{
            margin-right: $unit-small;
        }
    }

    // Error object
    .o-error{
        color: $error !important;
        opacity: 0;
        transform: translateY(5px);
        transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;
    }

    // Helper object
    .o-help{
        opacity: 1;
        transition: opacity 150ms 75ms $ease-sharp;
    }

    // Dropdown arrow icon
    .o-arrow{
        @include position(absolute, 50% 12px null null);
        transform: translateY(-60%);
        color: $off-black;

        section.-dark-background & {
            color: $font-alt;
        }
    }

    // Text area has a universal style
    textarea{
        padding: 32px 16px 16px;
        border-radius: 4px;
        border: 1px solid $off-black;
        width: 100%;
        min-height: 106px;
        color: $font-base;
        background: transparent;
        transition: border 75ms $ease-sharp, padding 75ms $ease-sharp;

        section.-dark-background & {
            border: 1px solid rgba($off-white, 0.3);
            color: $font-alt;
        }

        & + label{
            position: absolute;
            top: 20px;
            left: 16px !important;
        }

        &:focus{
            border: 2px solid $primary;
            padding: 31px 15px 15px;
            transition: border 150ms $ease-in, padding 150ms $ease-in;

            section.-dark-background & {
                border-color: $primary-alt;
            }

            & + label{
                top: 12px;
                font-size: 12px !important;
                color: $primary !important;

                section.-dark-background & {
                    color: $primary-alt !important;
                }
            }
        }
    }

    // General input settings
    input{
        &.has-icon{
            padding-left: 48px !important;
            padding-right: 16px !important;
        }
    }

    .o-icon{
        position: absolute;
        left: 17px;
        bottom: 17px;
        font-size: 22px;
    }

    // Outline Input Style
    @import './form-styles/outline';

    // Solid Input Style
    @import './form-styles/solid';

    // Base Style
    @import './form-styles/base';
}

// Normalize Inputs
// ==========================================================================

input,
select,
textarea,
fieldset{
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 16px;

    &:focus,
    &:active{
        box-shadow: none;
        border: none;
        outline: none;
    }

    &:invalid{
        box-shadow: none;
    }
}

input,
select{
    background: transparent;
    color: rgba($black, 0.87);
    white-space: nowrap;
    text-overflow: ellipsis;

    section.-dark-background & {
        color: rgba($font-alt, 0.87);
    }
}

input[type=radio],
input[type=checkbox]{
    @include size(0px,0px);
    position: absolute;
    border: none;
    outline: none;
    background: transparent;
    opacity: 0;
    visibility: hidden;
}

input,
textarea{
    section.-dark-background & {
        ::placeholder{
            color: rgba($font-alt, 0.87);
        }
    }

    ::placeholder{
        color: rgba($black, 0.87);
    }
}

input[type=date]{
    text-indent: -1px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

select{
    @media (min-width: $screen-large){
        text-indent: -1px;
    }

    html.has-js & {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
}

input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0; 
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}