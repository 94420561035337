// ==========================================================================
// Utilities / Spacing
// ==========================================================================

////
/// Utility classes to put specific spacing values onto elements. The below loop
/// will generate us a suite of classes like:
///
/// @example .u-margin-top
/// @link https://github.com/inuitcss/inuitcss/blob/512977a/utilities/_utilities.spacing.scss
////

$spacing-directions: (
    null:          null,
    '-top':        '-top',
    '-right':      '-right',
    '-bottom':     '-bottom',
    '-left':       '-left',
    '-horizontal': '-left' '-right',
    '-vertical':   '-top'  '-bottom',
) !default;

$spacing-properties: (
    'padding': 'padding',
    'margin':  'margin',
) !default;

$spacing-sizes: (
    null        :   $unit,
    '-none'     :   0,
    '-tiny'     :   $unit-tiny,
    '-thin'     :   $unit-small,
    '-x2'       :   $unit*2,
    '-x4'       :   $unit*4,
    '-x6'       :   $unit*6,
    '-x8'       :   $unit*8,
) !default;

@each $property-namespace, $property in $spacing-properties {
    @each $direction-namespace, $direction-rules in $spacing-directions {
        @each $size-namespace, $size in $spacing-sizes {
            .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {
                @each $direction in $direction-rules {
                    #{$property}#{$direction}: $size !important;
                }
            }
        }
    }
}