// ==========================================================================
// Tools / Mixins
// ==========================================================================

//
// Micro clearfix rules for containing floats.
//
// @link   http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
// @param  {List} $supports The type of clearfix to generate.
// @output Injects `:::after` pseudo-element.
//
@mixin u-clearfix($supports...)
{
    &::after
    {
        display: if(list-contains($supports, table), table, block);
        clear: both;

        content: if(list-contains($supports, opera), ' ', '');
    }
}

@mixin touch{
    &.has-touch{
        html.has-touch & {
            @content;
        }
    }
}

@mixin hover{
    &:hover{
        html.has-no-touch & {
            @content;
        }
    }
}

@mixin active{
    &:active{
        html.has-no-touch & {
            @content;
        }
    }
}

//
// Prevent text from wrapping onto multiple lines for the current element.
//
// An ellipsis is appended to the end of the line.
//
// 1. Ensure that the node has a maximum width after which truncation can occur.
// 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor nodes.
//
// @param  {Number} $width [100%] - The maximum width of element.
// @output `max-width`, `word-wrap`, `white-space`, `overflow`, `text-overflow`
//
@mixin u-truncate($width: 100%)
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;  // [2]
    @if $width
    {
        max-width: $width; // [1]
    }
}

//
// Applies flexbox based modifiers. Typically used for container and wrapper objects.
//
// @output Several flexbox based modifiers
//
@mixin u-flexbox{
    &.-flex{
        display: inline-flex;
    }

    &.-flexbox{
        display: flex;
    }

    &.-flex,
    &.-flexbox{
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        &.-no-wrap{
            flex-wrap: nowrap;
        }

        // Alignment
        &.-align-end{
            align-items: flex-end;
        }

        &.-align-center{
            align-items: center;
        }

        // Justify
        &.-justify-end{
            justify-content: flex-end;
        }

        &.-justify-center{
            justify-content: center;
        }

        &.-space-between{
            justify-content: space-between;
        }

        &.-space-around{
            justify-content: space-around;
        }

        // Utilities
        &.-full-center{
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &.-col-reverse{
            flex-direction: column-reverse;
        }

        &.-row-reverse{
            flex-direction: row-reverse;
        }
    }
}

//
// Sets elements height to be 100%. Typically used on container and warpper objects
// to convert them to be fullscreen.
//
// @output .-full and .-fullscreen
//
@mixin u-fullscreen{
    &.-full{
        height: 100%;
    }

    &.-fullscreen{
        height: 100vh;
    }
}

@mixin u-mobile-padding{
    &.u-mobile-padding{
        @media (max-width: $screen-xsmall){
            padding-left: 16px !important;
            padding-right: 16px !important;
        }
        @media (min-width: $screen-xsmall) and (max-width: $screen-container){
            padding-left: 24px !important;
            padding-right: 24px !important;
        }
    }
}
