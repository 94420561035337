// Aspect Ratios
// ==========================================================================

$aspect-ratios: (
    (1:1),
    (2:1),
    (7:3),
    (36:10),
    (4:3),
    (16:9),
    (5:4),
    (3:2),
    (3:1),
    (2:3)
) !default;

/**
* By default, the boilerplate uses fractions-like classes like `<div class="u-1/4">`.
* You can change the `/` to whatever you fancy with this variable.
*/
$aspect-delimiter: \/ !default;

/**
 * Aspect ratio, create a :before pseudo element for setting an aspect ratio on an element
 *
 * @param {number} $width (1) - Percentage width
 * @param {number} $height ($width) - Percentage height
 * @param {boolean} $relative (true) - Add relative position to element
 *
 */
@mixin aspect-ratio($width: 1, $height: $width, $relative: true) {
    @if ($relative == true) {
        position: relative;
    }

    &:before {
        content: '';
        display: block;
        padding-top: unquote(($height / $width) * 100 + '%');
    }
}

/**
* Generates aspect ratio utility classes
* 
* @example
*   <div class="u-ratio-16/9"></div>
*/
@each $ratio in $aspect-ratios
{
    @each $numerator, $denominator in $ratio
    {
        @if (type-of($numerator) != number)
        {
            @error '`#{$numerator}` needs to be a number.'
        }

        @if (type-of($denominator) != number)
        {
            @error '`#{$denominator}` needs to be a number.'
        }

        .u-ratio-#{$numerator}#{$aspect-delimiter}#{$denominator}
        {
            @include aspect-ratio($numerator, $denominator);
        }
    }
}