&.-outline{
    .o-input{
        &:after{
            display: none !important;
        }

        &.is-invalid{
            input{
                border: 2px solid rgba($error, 0.87);
                padding: 11px 15px 9px;
                transition: all 150ms $ease;

                & + label{
                    color: $error;
                    font-size: 12px;
                    bottom: 48px;
                    left: 16px;
                }
            }
        }

        html.has-js & {
            &.has-value{
                input,
                select{
                    padding: 11px 15px 9px;
                }

                label{
                    font-size: 12px;
                    bottom: 48px;
                    left: 16px;
                }
            }

            &.is-invalid{    
                .o-error{
                    opacity: 1;
                    transform: translateY(0);
                    transition: transform 150ms $ease-in, opacity 25ms $ease-in;
                }
            }
        }

        input,
        select{
            & + label{
                bottom: 18px;
                left: 12px;
                position: absolute;

                html.has-no-js & {
                    font-size: 12px;
                    bottom: 48px;
                }
            }
        }
    }

    input{
        &.has-icon{
            padding-left: 48px !important;
            padding-right: 16px !important;

            & + label{
                left: 48px;
            }
        }
    }

    label{
        color: rgba($black, 0.6);
        pointer-events: none;
        font-size: 16px;
        transition: all 75ms $ease-out;
        background: $white;

        section.-dark-background & {
            color: rgba($font-alt, 0.6);
            background: $dark-background-color;
        }
    }

    input,
    select{
        height: 100%;
        background: transparent;
        border-radius: 4px;
        padding: 12px 16px 10px;
        border: 1px solid rgba($off-black, 0.3);
        transition: all 75ms $ease-sharp;

        section.-dark-background & {
            border: 1px solid rgba($off-white, 0.3);
        }

        & + label{
            position: absolute;
            left: 16px;
            bottom: 20px;
            padding: 0 4px;
        }

        &:hover{
            border: 1px solid rgba($off-black, 0.87);
            transition: border 150ms $ease-in;
        }

        &:focus{
            border: 2px solid rgba($primary, 0.87);
            padding: 11px 15px 9px;
            transition: all 150ms $ease-in;

            section.-dark-background & {
                border-color: rgba($primary-alt, 0.87);
            }

            & + label{
                color: $primary;
                font-size: 12px;
                bottom: 48px;
                left: 16px;
                background: $white;
                transition: all 150ms $ease-in;

                section.-dark-background & {
                    background: $dark-background-color;
                    color: $primary-alt;
                }
            }
        }
    }
}