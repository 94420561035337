.o-gallery{
    text-align: center;

    .o-gallery_shim{
        position: relative;
        display: inline-block;
        width: 100%;
        overflow: hidden;

        &.u-ratio-2\/3{
            max-width: 520px;
        }

        .o-gallery_slide{
            @include position(absolute, 0 null null 0);
            @include size(100%, 100%);
            display: inline-block;
            overflow: hidden;

            .o-gallery_image{
                @include position(absolute, 0 null null 0);
                @include size(100%, 100%);
            }
        }

        .o-gallery_controls{
            @include size(100%, 48px);
            @include position(absolute, null 0 0 null);
            text-align: right;
            z-index: 5;
            user-select: none;
            cursor: pointer;

            .o-gallery_button{
                @include size(32px, 48px);
                background-color: rgba($primary, 0.87);
                color: contrast($primary, 1);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: all 150ms $ease-out;

                &:hover{
                    background-color: contrast($primary, 1);
                    color: rgba($primary, 0.87);
                    transition: all 150ms $ease-in;
                }

                svg{
                    display: inline-block;
                    position: absolute;
                    @include size(18px, 18px);
                }
            }
        }
    }
}