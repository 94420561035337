&.-solid{
    .o-input{
        &.is-invalid{
            input{
                & + label{
                    color: $error;
                    font-size: 12px;
                    bottom: 34px;
                }
            }
        }

        html.has-js & {
            &::after{
                content: "";
                @include size(100%, 2px);
                @include position(absolute, null null 0 0);
                transform: scaleX(0);
                display: inline-block;
                transition: transform 75ms $ease-out;
                background: $primary;
                transform-origin: center;

                section.-dark-background & {
                    background: $primary-alt;
                }
            }

            &.has-focus{
                &:after{
                    transform: scaleX(1);
                    transition: transform 150ms $ease-in;
                }
            }

            &.has-value{
                label{
                    font-size: 12px;
                    bottom: 35px;
                }
            }

            &.is-invalid{
                &:after{
                    transform: scaleX(1);
                    background: $error;
                    transition: background 75ms $ease;
                }

                .o-error{
                    opacity: 1;
                    transform: translateY(0);
                    transition: transform 150ms $ease-in, opacity 25ms $ease-in;
                }
            }
        }

        input,
        select{
            & + label{
                bottom: 18px;
                left: 12px;
                position: absolute;

                html.has-no-js & {
                    font-size: 12px;
                    bottom: 35px;
                }
            }
        }
    }

    label{
        color: rgba($black, 0.6);
        pointer-events: none;
        font-size: 16px;
        transition: all 75ms $ease-out;

        section.-dark-background & {
            color: rgba($white, 0.6);
        }
    }

    input{
        &.has-icon{
            & + label{
                left: 48px;
            }
        }
    }

    input,
    select{
        height: 100%;
        padding: 20px 12px 6px;
        background: $off-white;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid rgba($black, 0.42);
        transition: border-bottom-color 75ms $ease-out, background 150ms $ease;

        section.-dark-background & {
            border-bottom: 1px solid rgba($white, 0.42);
            background: darken($dark-background-color, 2);
        }

        &:hover{
            background: darken($off-white, 3);

            section.-dark-background & {
                background: darken($dark-background-color, 3);
            }
        }

        &:focus{
            background: darken($off-white, 5);

            section.-dark-background & {
                background: darken($dark-background-color, 5);
            }

            html.has-no-js & {
                border-bottom-color: $primary;
                transition: border-bottom-color 150ms $ease-in;

                section.-dark-background & {
                    border-bottom-color: $primary-alt;
                }
            }

            & + label{
                color: $primary;
                font-size: 12px;
                bottom: 35px;
                transition: all 150ms $ease-in;

                section.-dark-background & {
                    color: $primary-alt;
                }
            }
        }
    }
}