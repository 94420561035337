// States
// ==========================================================================

/**
 * Control visibility without affecting flow.
 */
.is-visible
{
    visibility: visible !important;
    opacity: 1 !important;
}

.is-hidden
{
    visibility: hidden !important;
    opacity: 0 !important;
}