// ==========================================================================
// Objects / Buttons
// ==========================================================================

//
// 1. Allow us to style box model properties.
// 2. Fixes odd inner spacing in IE7.
// 3. Line different sized buttons up a little nicer.
// 4. Force all button-styled elements to appear clickable.
//
.o-button
{
    // Base
    color: $primary;
    letter-spacing: 1px;
    font-weight: $medium;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    line-height: 37px;

    padding: 0 $unit-small;
    min-width: 64px;
    height: 36px;
    margin: 0;

    display: inline-block;
    user-select: none;
    overflow: hidden;
    border-radius: 4px;
    background: transparent;
    position: relative;
    opacity: 0.87;

    section.-dark-background & {
        color: $primary-alt;
    }

    &:not(.-icon){
        &::before{
            content:"";
            @include size(100%, 100%);
            @include position(absolute, 0 null null 0);
            background-color: $primary;
            opacity: 0;
            transition: opacity 125ms $ease-out;

            section.-dark-background & {
                background-color: $primary-alt;
            }
        }
    }

    @include touch{
        &:before{
            opacity: $primary-active-opacity;
            transition: opacity 75ms $ease-sharp;
        }
    }

    @include hover{
        &:before{
            opacity: $primary-hover-opacity;
            transition: opacity 150ms $ease-in;
        }
    }

    @include active{
        &:before{
            opacity: $primary-active-opacity;
            transition: opacity 75ms $ease-sharp;
        }
    }

    // Modifier Classes
    &.is-disabled{
        color: rgba($black,0.26);
        pointer-events: none;

        section.-dark-background & {
            color: rgba($white, 0.3);
        }
    }

    &.-wide{
        display: block;
    }

    &.-round{
        border-radius: 17px;
    }

    &.has-icon{
        padding: 0 $unit;

        svg{
            @include size(18px, 18px);
            transform: translateY(3px);
            margin-right: $unit-small;
        }
    }

    // Solid
    &.-solid{
        padding: 0 $unit;
        background: $primary;
        color: contrast($primary);

        section.-dark-background & {
            background: $primary-alt;
            color: contrast($primary-alt);

            &:before{
                background-color: contrast($primary-alt);
            }
        }

        &:before{
            background-color: contrast($primary);
        }

        @include hover{
            &:before{
                opacity: 0.1;
            }
        }

        @include active{
            &:before{
                opacity: 0.2;
            }
        }

        &.is-disabled{
            background: rgba($black,0.1);
            color: rgba($black,0.26);

            section.-dark-background & {
                background: rgba($white,0.05);
                color: rgba($white, 0.3);
            }
        }
    }

    // Outline
    &.-outline{
        padding: 0 $unit;
        border: 2px solid $primary;
        line-height: 34px;

        section.-dark-background & {
            border-color: $primary-alt;
        }

        &.-alt{
            border-color: rgba($black, 0.1);
            transition: border-color 150ms $ease;

            section.-dark-background & {
                border-color: rgba($white, 0.1);

                @include hover{
                    border-color: $primary-alt;
                }
            }

            @include hover{
                border-color: $primary;
            }
        }

        &.is-disabled{
            background: rgba($black,0.04);
            color: rgba($black,0.26);
            border-color: rgba($black, 0.1);

            section.-dark-background & {
                background: rgba($white,0.05);
                color: rgba($white, 0.3);
                border-color: rgba($white, 0.04);
            }
        }
    }

    // Raised
    &.-raised{
        padding: 0 $unit;
        background: $primary;
        color: $white;
        box-shadow: 0 3px 1px -2px rgba($black,.2),0 2px 2px 0 rgba($black,.14),0 1px 5px 0 rgba($black,.12);
        transition: box-shadow 150ms $ease-out;

        &:before{
            background-color: $white;
        }

        @include hover{
            box-shadow: 0 2px 4px -1px rgba($black,.2),0 4px 5px 0 rgba($black,.14),0 1px 10px 0 rgba($black,.12);
            transition: box-shadow 150ms $ease-in;

            &:before{
                opacity: 0.08;
            }
        }

        @include active{
            &:before{
                opacity: 0.33;
            }
        }

        &.is-disabled{
            background: rgba($black,0.1);
            color: rgba($black,0.26);

            section.-dark-background & {
                background: rgba($white,0.05);
                color: rgba($white, 0.3);
            }
        }
    }

    // Icon ONLY
    &.-icon{
        background: rgba($black,0);
        color: $primary;

        html.has-js.has-no-touch & {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            .-active{
                display: none;
            }

            &.is-active{
                .-inactive{
                    display: none;
                }

                .-active{
                    display: initial;
                }
            }

            &::before{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                transform-origin: center;
                opacity: 0;
                transition: transform 125ms $ease-sharp, opacity 25ms 100ms $ease-out;
            }

            &::after{
                content:"";
                background: $primary;
                position: absolute;
                display: inline-block;
                border-radius: 100%;
                @include size(32px, 32px);
                transform: scale(0);
                opacity: 0;
                transition: transform 75ms $ease-sharp, opacity 25ms 50ms $ease-out;
            }

            svg{
                @include size(18px, 18px);
            }

            .-alt{
                display: none !important;
            }

            &:hover{
                &:before{
                    transform: scale(1);
                    opacity: $primary-hover-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }
            }

            &:active{
                &:before{
                    opacity: 0;
                    transition: opacity 75ms $ease-out;
                }

                &:after{
                    transform: scale(1);
                    opacity: $primary-active-opacity;
                    transition: transform 150ms $ease-in, opacity 75ms $ease-in;
                }
            }
        }

        &:before,
        &:after{
            section.-dark-background & {
                background: $white !important;
            }
        }
    }
}
