.c-transition{
    @include size(100vw, 4px);
    @include position(fixed, 0 null null 0);
    z-index: 999999;
    transform: translate3d(0, -101%, 0);
    transition: transform 125ms $ease-sharp;
    background: $off-white;
    overflow: hidden;
    display: none;

    .c-transition_primary,
    .c-transition_secondary{
        position: absolute;
        @include size(100%, 100%);

        &::before{
            content: "";
            background: $notice;
            display: inline-block;
            position: absolute;
            @include size(100%, 100%);
        }
    }

    .c-transition_primary{
        left: -145%;
    }

    .c-transition_secondary{
        left: -54%;
    }

    html.has-no-js & {
        display: none;
    }

    html.dom-is-loading & {
        transform: translate3d(0, 0, 0);
        display: inline-block;
        transition: transform 150ms $ease-in;

        .c-transition_primary{
            animation: primaryBar 2s infinite linear;

            &:before{
                animation: primaryScale 2s infinite linear;
            }
        }

        .c-transition_secondary{
            animation: secondaryBar 2s infinite linear;

            &:before{
                animation: secondaryScale 2s infinite linear;
            }
        }
    }
}

@keyframes primaryBar{
    0%{
        transform: translateX(0);
    }
    20%{
        transform: translateX(0);
        animation-timing-function: cubic-bezier(.5,0,.70173,.49582);
    }
    59%{
        transform: translateX(83.67142%);
        animation-timing-function: cubic-bezier(.30244,.38135,.55,.95635);
    }
    100%{
        transform: translateX(200.61106%);
    }
}

@keyframes secondaryBar{
    0%{
        transform: translateX(0);
        animation-timing-function: cubic-bezier(.15,0,.51506,.40969);
    }
    25%{
        animation-timing-function: cubic-bezier(.31033,.28406,.8,.73371);
        transform: translateX(37.65191%);
    }
    48.35%{
        animation-timing-function: cubic-bezier(.4,.62704,.6,.90203);
        transform: translateX(84.38617%);
    }
    100%{
        transform: translateX(160.27778%);
    }
}

@keyframes primaryScale{
    0%{
        transform: scaleX(.08);
    }
    36%{
        animation-timing-function: cubic-bezier(.33473,.12482,.78584,1);
        transform: scaleX(.08);
    }
    69%{
        animation-timing-function: cubic-bezier(.06,.11,.6,1);
        transform: scaleX(.66148);
    }
    100%{
        transform: scaleX(.08);
    }
}

@keyframes secondaryScale{
    0%{
        animation-timing-function: cubic-bezier(.20503,.05705,.57661,.45397);
        transform: scaleX(.08);
    }
    19%{
        animation-timing-function: cubic-bezier(.15231,.19643,.64837,1.00432);
        transform: scaleX(.4571);
    }
    44%{
        animation-timing-function: cubic-bezier(.25776,-.00316,.21176,1.38179);
        transform: scaleX(.72796);
    }
    100%{
        transform: scaleX(.08);
    }
}