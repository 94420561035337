// ==========================================================================
// Objects / Tables
// ==========================================================================
table{
    display: inline-block;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;
    white-space: nowrap;

    /**
     * Force all cells within a table to occupy the same width as each other.
     * @see https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
     */

    thead{
        margin: 0;
        padding: 0;
        vertical-align: middle;

        th{
            height: 64px;
            padding: 0 14px 0 24px;
            font-size: 20px;
            text-transform: capitalize;
        }
    }

    tbody{
        margin: 0;
        border: 0;
        display: inline-block;
        vertical-align: baseline;

        tr{
            margin: 0;
            padding: 0;
            vertical-align: baseline;
            font-size: 13px;
            height: 48px;
            background: transparent;
            border-bottom: 2px solid darken($base-background-color, 3);
            transition: background 150ms $ease;

            section.-secondary-background & {
                border-bottom: 2px solid darken($secondary-background-color, 3);
            }

            section.-dark-background & {
                border-bottom: 2px solid darken($dark-background-color, 2);
            }

            &:hover{
                &:not(:first-of-type){
                    background: darken($base-background-color, 1);

                    section.-secondary-background & {
                        background: darken($secondary-background-color, 2);
                    }

                    section.-dark-background & {
                        background: lighten($dark-background-color, 1);
                    }
                }
            }

            td{
                padding: 0;
                margin: 0;
                border: 0;
                vertical-align: middle;
                text-align: left;
                padding-left: 56px;

                &.-number{
                    text-align: right;
                }

                &:first-of-type{
                    padding-left: 24px;
                    padding-right: 24px;
                }

                &:last-of-type{
                    padding-right: 24px;
                }
            }

            &:first-of-type{
                font-size: 12px;
                height: 56px;

                td{
                    text-align: right;
                    padding-left: 56px;

                    &:first-of-type{
                        text-align: left;
                        padding-left: 24px;
                        padding-right: 24px;
                    }

                    &:last-of-type{
                        padding-right: 24px;
                    }
                }
            }
        }
    }
}
