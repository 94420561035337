// Layout
// ==========================================================================
// The layout object provides us with a column-style layout system. This file
// contains the basic structural elements, but classes should be complemented
// with width utilities
// @source https://github.com/inuitcss/inuitcss/blob/0420ba8/objects/_objects.layout.scss

.o-layout
{
    @include u-flexbox;

    // Gutter modifiers
    @media (min-width: $screen-container){
        &.-gutter{
            margin-left: -$gutter / 2;
            margin-right: -$gutter / 2;
        }

        &.-gutter-small{
            margin-left: -$gutter-small / 2;
            margin-right: -$gutter-small / 2;
        }

        &.-gutter-large{
            margin-left: -$gutter-large / 2;
            margin-right: -$gutter-large / 2;
        }
    }
}

// Layout Item
// ==========================================================================
// 1. Required in order to combine fluid widths with fixed gutters.
// 2. By default, all layout items are full-width (mobile first).
// 3. Default item alignment is with the tops of each other, like most traditional grid/layout systems.
.o-layout_item
{
    display: inline-flex; // [1]
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%; // [2]
    vertical-align: top; // [3]
    position: relative;

    // Gutter modifiers
    @media (min-width: $screen-container){
        .o-layout.-gutter > &
        {
            padding-left: $gutter / 2;
            padding-right: $gutter / 2;
        }

        .o-layout.-gutter-small > &
        {
            padding-left: $gutter-small / 2;
            padding-right: $gutter-small / 2;
        }

        .o-layout.-gutter-large > &
        {
            padding-left: $gutter-large / 2;
            padding-right: $gutter-large / 2;
        }
    }
}
