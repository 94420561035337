// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

/*
 * NOTE: `-alt` colours are used to style elements when they're within a section
 * that has the `$dark-background-color` applied. Test your colours on both backgrounds
 * at the example page.
*/

// Base Palette
// =============================================================================
$white:     #ffffff;
$off-white: #fafafa;
$light-gray:#eeeeee;
$black:     #000000;
$off-black: #414141;
$cool-black:#4b4e6d;

// Brand Palette
// =============================================================================
$primary:       #0496FF;
$secondary:     #c2418f;
$primary-alt:   $primary;
$secondary-alt: $secondary;

// Interaction Settings
// =============================================================================
$primary-hover-opacity: 0.07;
$primary-active-opacity: 0.15;

// Font Palette
// =============================================================================
$font-base:     $off-black;
$font-alt:      $white;
$helper-light:  rgba(255,255,255,0.5);
$helper-dark:   rgba(0,0,0,0.5);

// Selection
// =============================================================================
$selection-text-color:       #3b83f9;
$selection-background-color: $light-gray;

// Background Colors
// =============================================================================
$base-background-color:         $white;
$secondary-background-color:    $off-white;
$dark-background-color:         $off-black;

// Alert Colors
// =============================================================================
$notice:    #3b83f9;
$success:   #4de07e;
$error:     #ff715b;
$warning:   #ffa412;

// Social Media Colors
// =============================================================================
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagrammagenta: #c32aa3;
$instagramblue: #4c5fd7;
$instagrampurple: #7232bd;
$instagramorange: #f46f30;
$instagramyellow: #ffdc7d;
$googleblue: #4285f4;
$googlered: #ea4335;
$googleyellow: #fbbc05;
$googlegreen: #34a853;
$pinterest: #bd081c;
$googleplus: #db4437;
$linkedin: #007bb5;
$vimeoblue: #1ab7ea;
$tumblr: #2c4762;
$snapchat: #fffc00;
$whatsappgreen: #25d366;
$whatsappteal1: #075e54;
$whatsappteal2: #128c7e;
$mastodon: #2b90d9;
$apple: #a6b1b7;
$amazon: #ff9900;
$microsoftred: #f35022;
$microsoftgreen: #80bb03;
$microsoftblue: #03a5f0;
$microsoftyellow: #ffb903;
$periscope: #40a4c4;
$foursquarepink: #f94877;
$foursquarenavy: #073282;
$foursquareblue: #2d5be3;
$swarm: #ffa633;
$medium: #02b875;
$skypeblue: #00aff0;
$skypedkblue: #0078d7;
$android: #a4c639;
$stumbleupon: #e94826;
$flickrpink: #f40083;
$flickrblue: #006add;
$yahoo: #430297;
$twitch: #6441a4;
$soundcloud: #ff5500;
$spotifygreen: #1ed760;
$spotifydarkgreen: #1db954;
$dribbble: #ea4c89;
$slackpurple: #4d394b;
$slackskyblue: #70cadb;
$slackmosss: #3eb890;
$slackpink: #e01765;
$slackyellow: #e8a723;
$slackgreenblue: #1a937d;
$slackviolet: #472a49;
$slackolive: #65863a;
$slackred: #cc2027;
$reddit: #ff5700;
$deviantart: #05cc47;
$pocket: #ee4056;
$quora: #aa2200;
$quorablue: #2b6dad;
$slideshareorange: #e68523;
$slideshareblue: #00a0dc;
$fivehundredpx: #0099e5;
$vk: #4a76a8;
$listlyorange: #df6d46;
$listlyblue: #52b1b3;
$vine: #00b489;
$steam: #171a21;
$discord: #7289da;
