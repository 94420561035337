.o-links{
    @include position(fixed, 0 0 null null);
    white-space: nowrap;
    margin-right: $unit*2;
    margin-top: $unit;
    display: inline-flex;
    flex-flow: row nowrap;

    @media (max-width: $screen-medium){
        flex-flow: column wrap;
        margin-right: $unit;
        margin-top: $unit*2;
    }

    a{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        @include size(48px, 48px);
        background-color: $white;
        box-shadow: 0 2px 4px rgba($cool-black, 0.1);
        transition: color 150ms $ease;
        margin-right: $unit;
        border-radius: 100%;
        font-size: 22px;

        &:last-of-type{
            margin-right: 0;
        }

        @media (max-width: $screen-medium){
            margin-bottom: $unit;

            &:last-of-type{
                margin-bottom: 0;
            }
        }

        @include touch{
            color: $primary;
        }
        
        @include hover{
            color: $primary;
        }
    }
}